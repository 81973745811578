.dialog_inner {
	min-width: 400px;
	padding: 0 20px;

	.dialog_inner_item {
		margin-top: 0;
	}
}

.dialog_header {
	display: flex;
	justify-content: space-between;

	h3,
	i {
		display: block;
	}

	h3 {
		margin-block-end: 0;
	}
}

.inSameLine {
	display: flex;
	align-items: baseline;

	> button {
		margin-left: 10px;
	}
}

.editSpinner {
	width: 30px;
	height: 30px;
	margin: 0 10px;
}
  