$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.btn {
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  padding: 10px 0px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  min-width: 80px;
  background: $blue;
  box-shadow: 1px 1px 5px 2px rgba(131, 142, 154, 0.6);
  outline: none;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 250ms;
  padding: 10px;
}
.btn:last-child {
  margin-right: 0;
}
.btn:hover {
  background: rgba(255, 255, 255, 0.8);
  color: $blue;
  cursor: pointer;
}
.btn:active {
  transform: scale(0.9);
  outline: none;
}
.is-disabled {
  opacity: 0.3;
}
.btn_sm {
  padding: 5px 25px !important;
  font-size: 12px;
  line-height: 13.8px;
}

.btn_link {
  display: inline-block;
  box-shadow: none;
  background: none;
  margin-left: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  color: #2186eb;
  padding: 0;
  min-width: unset;
  border: none;
  font-weight: 600;
  outline: none;
}
.btn_link:hover {
  text-decoration: underline;
  background: none;
  color: $blue;
}
.btn_link_red {
  color: $red;
}
.btn_link_red:hover {
  color: $red;
}
.btn_red {
  background: $red;
}
.btn_red:hover {
  color: $red;
}
.btn_warn {
  background: $orange;
}

.confirmDialog {
  position: absolute;
  width: 300px;
  min-height: initial;
  min-width: initial;

  .confirmDialogInner {
    display: flex;
    justify-content: center;
  }
}

.dialog_inner {
  width: 300px;
  max-width: 300px;
  padding: 0 20px;

  .dialog_inner_item {
    margin-top: 0;
  }
}

.dialog_header {
  width: 300px;
  max-width: 300px;
  display: flex;
  justify-content: space-between;

  h3,
  i {
    display: block;
  }

  h3 {
    margin-block-end: 0;
  }
}
