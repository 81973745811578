.inSameLine {
  display: flex;
  align-items: baseline;

  > div {
	  margin-left: 10px;
  }

  > label {
	  width: 120px;
  }
}
