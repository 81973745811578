$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);
$border-color: #efefef;

.schedulerTable {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	max-width: 1000px;
	border: 1px solid $border-color;
	border-radius: 5px;
}

.header {
	border-bottom: 2px solid $border-color;
	text-transform: uppercase;
	color: #000;
	font-weight: 600;
	font-size: 12px;
	background: $nav-background;
}

.headerCell {
	padding: 12px 20px;
	text-align: center;
}

.headerCellEqualWidth {
	width: 20%;
}

.tableBodyRow {
	border-bottom: 1px solid $border-color;

	> td {
		text-align: center;
		padding: 12px 10px;
	}

	> td:first-child {
		padding-left: 12px;
	}

	> td:last-child {
		padding-right: 12px;
	}
}

.schedulerActions {
	display: flex;
	width: 100%;
	justify-content: center;

	> button {
		display: inline-block;
		box-shadow: none;
		background: none;
		margin-left: 5px;
		cursor: pointer;
		text-transform: uppercase;
		font-size: 12px;
		color: #2186eb;
		padding: 0;
		min-width: unset;
		border: none;
		font-weight: 600;
		outline: none;
		text-decoration: underline;

		&:hover {
			text-decoration: underline;
			background: none;
			color: $blue;
		}
	}
}

.dialog_inner {
	min-width: 400px;
	padding: 0 20px;

	.dialog_inner_item {
		margin-top: 0;
	}
}

.dialog_header {
	display: flex;
	justify-content: space-between;

	h3,
	i {
		display: block;
	}

	h3 {
		margin-block-end: 0;
	}
}

.inSameLine {
	display: flex;
	align-items: baseline;

	> button {
		margin-left: 10px;
	}
}

.editControl {
	width: 200px;
}

.editSpinner {
	width: 30px;
	height: 30px;
	margin: 0 10px;
}
  